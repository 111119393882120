import React from "react"
// import Poster from "./Components/Overly/Poster";
import Poster from "./Components/Northernwarriors/Poster"
function App() {
  return (
    <div className="App">
      <Poster/>
   {/* <Practice/>   */}
    </div>
  );
}

export default App;
