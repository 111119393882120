import React, { useEffect } from "react";
import ReactGA from "react-ga4";

const Poster = () => {
  useEffect(() => {
    ReactGA.initialize("G-TCYMVV8JNG");
    ReactGA.send("pageview");
  }, []);

  const handleClaimNowClick = () => {
    ReactGA.event({
      category: "CTA",
      action: "Claim Now Button Clicked",
      label: "Claim Bonus",
    });
    window.open("https://wa.link/ggnow", "_blank");
  };
  return (
    <div className="w-full h-screen ">
      <div
        className="w-full relative h-screen"
        onClick={handleClaimNowClick}
        style={{
          backgroundImage: "url('/bgnorthen.jpg')",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="absolute top-0 inset-0 bg-black opacity-40 z-10"></div>
        <div className="grid grid-flow-row gap-[100px] md:gap-0  grid-cols-1 md:grid-cols-2 w-full h-full z-20  absolute top-0 md:grid-flow-col ">
          <div className="md:pt-28 pt-10 md:w-full w-[400px] pl-5  md:pl-16 md:space-y-12 relative ">
            <h1 className="md:text-8xl text-6xl text-white font-BebasNeue font-bold m-0 leading-none">
              Level Up Your Cricket Game with Northern Warriors!
            </h1>
            <h1 className="font-advertising font-bold text-[3rem] md:text-[5rem] text-yellow-500 mt-5 md:mt-0">
              Northern Warriors
            </h1>

            <button
              className="text-white px-20 animate-bounce absolute -bottom-20 md:bottom-28 left-5 md:left-32 py-3 border-2 border-white rounded-md bg-transparent text-lg md:text-xl uppercase font-bold m-0 "
              onClick={handleClaimNowClick}
            >
              Learn more
            </button>
          </div>
          <div className="w-full md:h-[100vh]  mt-5 h-[40vh] md:pt-10    ">
            <img
              className="w-full h-full object-contain"
              src="/northern warrrors.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Poster;
